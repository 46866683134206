<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2>Группы поправочных коэффициентов</h2>
      <div>
        <UIButton
          class="add-btn"
          text="Добавить"
          leftIcon="bi-plus"
          @click="goToAdd"
        />
      </div>
    </div>
    <TableSlot v-bind="cFGroups" @rowClick="goToDetails" />
  </div>
</template>

<script>
import TableSlot from "@/components/TableSlot";
import UIButton from "@/components/UI/Button";
import { API_cFGroup_list } from "@/services/references/cFGroup";

export default {
  name: "cFGroupList",
  components: { TableSlot, UIButton },
  data() {
    return {
      cFGroups: {
        columns: [
          {
            name: "Шифр",
            path: "WorkIdentifiedObject.IdentifiedObject.enproCode",
          },
          {
            name: "Наименование",
            path: "WorkIdentifiedObject.IdentifiedObject.name",
          },
        ],
        data: [],
      },
    };
  },
  mounted() {
    API_cFGroup_list().then((res) => (this.cFGroups.data = res));
  },
  methods: {
    goToDetails(item) {
      this.$router.push({
        path: `/references/technological-maps/cFGroup/${item.id}`,
      });
    },
    goToAdd() {
      this.$router.push({
        path: `/references/technological-maps/cFGroup/add`,
      });
    },
  },
};
</script>

<style scoped></style>
